import { createStore } from "vuex";
import api from "../components/http/api";
import msg from "../components/http/msgbox";
import { dist2 } from "@/components/Game/GameList";
export default createStore({
  state: {
    isLoading: false,
    islang: false,
    isPreImg: true,
    isann: false,
    pop: {
      isLoading: false,
      data: {
        action: "",
        msg: "",
        class: "",
        type: "",
      },
    },
    parameter: {
      action: "",
      body: {},
    },
    service: {
      qq: "",
    },
    list: [],
    balance: "",
    logo: "logo.png",
    flag_nav: false,
    watch_newWindowurl: "",
  },
  mutations: {
    Loading(state, payload) {
      state.isLoading = payload;
    },
    Lang(state, payload) {
      state.islang = payload;
    },
    PreImg(state, payload) {
      state.isPreImg = payload;
    },
    isann(state, payload) {
      state.isann = payload;
    },
    Pop(state, payload) {
      state.pop = payload;
    },
    Service(state, payload) {
      state.service.qq = payload;
    },
    GetIP() {
      api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    GetList(state, payload) {
      state.list = payload;
    },
    Getbalance(state, payload) {
      state.balance = payload;
    },
    GetLogo(state, payload) {
      state.logo = JSON.parse(payload)["logo"];
    },
    flagnav(state, payload) {
      state.flag_nav = payload;
    },
    watch_newWindowurl(state, payload) {
      state.watch_newWindowurl = payload;
    },
  },
  actions: {
    setGetIP(context) {
      context.commit("GetIP");
    },
    setLoading(context, payload) {
      context.commit("Loading", payload);
    },
    setLang(context, payload) {
      context.commit("Lang", payload);
    },
    showPop(context, payload) {
      context.commit("Pop", payload);
    },
    getpost(context, parameter) {
      return new Promise((resolve) => {
        api.apiPost(parameter.action, parameter.body).then((res) => {
          if (msg.resPonseStatus(res)) {
            if (res.status == "fail") {
              msg.API_popmsg(res.msg, "", "error");
            } else if (res.status == "relogin") {
              msg.API_popmsg(res.msg, "relogin", "error");
            } else {
              resolve(res);
            }
          }
        });
      });
    },
    getService(context) {
      this.dispatch("getpost", { action: "service" }).then((res) => {
        context.commit("Service", res);
      });
    },
    // getList(context, parameter) {
    //   context.commit("GetList", []);
    //   this.dispatch("getpost", parameter).then((res) => {
    //     try {
    //       context.commit("GetList", JSON.parse(res)["data"]);
    //     } catch {
    //       context.commit("GetList", JSON.parse(res)["list"]);
    //     }
    //   });
    // },
    getGameList(context, parameter) {
      parameter.body.gametype = parameter.body.gametype.toLowerCase();
      if (parameter.action != "") {
        if (
          localStorage.getItem(parameter.body.gametype + "_" + parameter.body.type + "_gamelist") == null ||
          parameter.body.gametype == "guess"
        ) {
          this.dispatch("getpost", parameter).then((res) => {
            var list = res["list"];
            list.forEach((v) => {
              if (
                (v["gamekind"] == "casino" &&
                  parameter.body.gametype == "guess") ||
                v["gamekind"] == "sport" ||
                v["gametype"] == "IGS"
              ) {
                v["iconName"] = dist2.filter((i) => {
                  return (
                    i["gameType"] == v["gametype"] &&
                    v["gamekind"] == i["gameKind"]
                  );
                })[0]["iconName"];
              } else {
                v["iconName"] = "http://gameweb.xkzkji.com/" + v["image"];
              }
              v["gameId"] = v["gameid"];
              v["gameType"] = v["gametype"];
              v["gameKind"] = v["gamekind"];
              v["gameName"] = v["gamename"];
            });
            localStorage.setItem(
              parameter.body.gametype + "_" + parameter.body.type + "_gamelist",
              JSON.stringify(list)
            );
            context.commit("GetList", list);
          });
        } else {
          list = JSON.parse(
            localStorage.getItem(parameter.body.gametype + "_" + parameter.body.type + "_gamelist")
          );
          list.forEach((v) => {
            if (
              // v["gamekind"] == "casino" ||
              v["gamekind"] == "sport" ||
              v["gametype"] == "IGS"
            ) {
              v["iconName"] = dist2.filter((i) => {
                return (
                  i["gameType"] == v["gametype"] &&
                  v["gamekind"] == i["gameKind"]
                );
              })[0]["iconName"];
            } else {
              v["iconName"] = "http://gameweb.xkzkji.com/" + v["image"];
            }
            v["gameId"] = v["gameid"];
            v["gameType"] = v["gametype"];
            v["gameKind"] = v["gamekind"];
            v["gameName"] = v["gamename"];
          });
          context.commit("GetList", list);
        }
      } else {
        if (
          localStorage.getItem(parameter.body.gametype + "_" + parameter.body.type + "_gamelist") == null
        ) {
          var list = [];
          list = dist2.filter((v) => {
            if (parameter.body.gametype === "all") {
              return v;
            } else {
              return (
                String(v["Gamestype"]).toLowerCase() == parameter.body.gametype
              );
            }
          });
          localStorage.setItem(
            parameter.body.gametype + "_" + parameter.body.type + "_gamelist",
            JSON.stringify(list)
          );
        } else {
          list = JSON.parse(
            localStorage.getItem(parameter.body.gametype + "_" + parameter.body.type + "_gamelist")
          );
        }
      }
      context.commit("GetList", list);
    },
    getLogo(context) {
      this.dispatch("getpost", {
        action: "Set_Logo",
        body: {
          affiliate: localStorage.getItem("agent") ?? "",
        },
      }).then((res) => {
        context.commit("GetLogo", res);
        if (
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i)
        ) {
          if (res["status"] == "fail") document.title = "Richking";
          else document.title = localStorage.getItem("agent");
          const link = document.querySelector("[rel='icon']");
          link.setAttribute("href", res["logo"]);
        } else {
          // location.href = "https://www.ybovn.com?agent=" + localStorage.getItem("agent") ?? "dybo";
        }
      });
    },
  },
  getters: {
    pop: (state) => {
      return state.pop;
    },
    islang: (state) => {
      return state.islang;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    isPreImg: (state) => {
      return state.isPreImg;
    },
    isann: (state) => {
      return state.isann;
    },
    service: (state) => {
      return state.service;
    },
    list: (state) => {
      return state.list;
    },
    logo: (state) => {
      return state.logo;
    },
    flag_nav: (state) => {
      return state.flag_nav;
    },
    watch_newWindowurl: (state) => {
      return state.watch_newWindowurl;
    },
  },
});
