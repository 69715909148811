<template>
  <div class="container help_m">
    <div class="row">
      <maintop1 />
      <maintop2 />
      <div
        :class="
          flag_nav ? 'main_fix_offset col-xl-10' : 'main_fix_width col-xl-12'
        "
        class="header_mt"
      >
        <KYCview v-if="Id === 'K'"></KYCview>
        <privacy v-else-if="Id === 'P'"></privacy>
        <Responsible v-else-if="Id === 'R'"></Responsible>
        <term v-else-if="Id === 'T'"></term>
        <faq v-else-if="Id === 'F'"></faq>
        <earn v-else-if="Id === 'E'"></earn>
        <Footer />
        <footertwo />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "@/assets/style/help.css";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import footertwo from "./Home/footer_two.vue";
import KYCview from "./kyc.vue";
import privacy from "./privacy.vue";
import Responsible from "./Responsible.vue";
import term from "./term.vue";
import faq from "./faq_home.vue";
import earn from "./earn.vue";

export default {
  name: "help",
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {
    Id() {
      document.getElementById("app").scrollTop = 0;
    },
  },
  computed: {
    ...mapGetters(["flag_nav"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  components: {
    Footer,
    footertwo,
    maintop1,
    maintop2,
    privacy,
    term,
    KYCview,
    Responsible,
    faq,
    earn
  },
  mounted() {
    document.getElementById("app").scrollTop = 0;
  },
};
</script>
