import Cookies from "js-cookie";
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Home2 from "../views/Home2.vue";
import Home3 from "../views/Home3.vue";
import help from "../views/footer_help.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:id",
    name: "igaming",
    component: Home,
  },
  {
    path: "/ME/:id",
    name: "ME",
    component: Home2,
  },
  {
    path: "/BO/:id",
    name: "home3",
    component: Home3,
  },
  {
    path: "/help/:id",
    name: "help",
    component: help,
  },
  {
    path: "/Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/Login/:id",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/VIP",
    name: "VIP",
    component: () => import("../views/Member.vue"),
  },
  {
    path: "/VIP/introduce",
    component: () => import("../views/vip_introduce.vue"),
  },
  {
    path: "/Promotion",
    name: "Promotion",
    component: () => import("../views/Promotion.vue"),
  },
  {
    path: "/slot",
    name: "slot",
    component: () => import("../views/slot.vue"),
  },
  {
    path: "/sport",
    name: "sport",
    component: () =>
      import("../views/sport.vue"),
  },
  {
    path: "/main_sport",
    name: "main_sport",
    component: () =>
      import("../views/main_sport.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (Cookies.get("code") == null) {
    if (
      to.path == "/" ||
      to.path == "/APP" ||
      to.path == "/Service" ||
      to.path == "/Login" ||
      to.path == "/Complaint" ||
      to.path == "/Promotion" ||
      to.path == "/Login/R" ||
      to.name == "igaming" ||
      to.name == "help" ||
      to.name == "main_sport"
    ) {
      next();
    } else {
      next({ path: "/Login" });
    }
  } else {
    next();
  }
});
export default router;
