<template>
  <div>
    <Loading
      :active="isLoading"
      :can-cancel="true"
    >
      <img
        src="@/assets/img/main/35771931234507.564a1d2403b3a.gif"
        class="w-100"
      />
    </Loading>
    <router-view />
    <pop></pop>
    <lang></lang>
    <ann></ann>
    <!-- <addcard></addcard> -->
    <!-- <changepassword></changepassword> -->
    <!-- <Signin></Signin>
  <teaching></teaching> -->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import pop from "./views/Home/pop.vue";
import Lang from "./views/Home/language.vue";
import ann from "./views/Home/announce.vue";
// import teaching from "./views/Home/teaching.vue";
// import Signin from "./views/Home/SigninDate.vue";
// import addcard from "./views/Home/addcard.vue";
// import changepassword from "./views/Home/changepassword.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  components: {
    Loading,
    pop,
    // addcard,
    // changepassword,
    Lang,
    ann,

    // teaching,
    // Signin,
  },
};
</script>
