<template>
  <div class="main_la_div p-3" style="display: block" v-if="islang">
    <div class="language_card">
      <div
        style="
          width: 100%;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-weight: bold;
        "
      >
        {{ $t("text_sele_lan") }}
      </div>
      <div class="overflow-x-auto main_la_div2">
        <div class="d-flex p-3">
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_china.png" />China
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_vietnam.png" />Vietnam
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img
              src="@/assets/img/ctry_ic/ctry_ic_philippines.png"
            />Phillippines
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_india.png" />India
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_brazil.png" />Brazil
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_southkorea.png" />South Korea
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_malaysia.png" />Malaysia
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_hongkong.png" />Hong Kong
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_japan.png" />Japan
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_indonesia.png" />Indonesia
          </div>
        </div>

        <div class="main_la_div2 d-flex p-3">
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_thailand.png" />Thailand
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_france.png" />France
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_germany.png" />Germany
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_italy.png" />Italy
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_portugal.png" />Portugal
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_nigeria.png" />Nigeria
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_pakistan.png" />Pakistan
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_myanmar.png" />Myanmar
          </div>
          <div
            class="word text-center d-flex align-items-center flex-column col-xl-10 col-4"
          >
            <img src="@/assets/img/ctry_ic/ctry_ic_usdt.png" />USDT
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      locale: localStorage.getItem("locale"),
      islang: true,
    };
  },
  computed: {},
  watch: {
    locale: function (e) {
      localStorage.setItem("locale", e);
      window.location.reload();
    },
  },
  methods: {
    lang: function (e) {
      this.locale = e;
    },
  },
};
</script>
