<template>
  <div>
    <div class="mt-3">
      <div class="p-2 bg-blue" :class="'home_game_' + show_id">
        <div class="d-flex justify-content-between">
          <div class="game_logo_img fw-bolder my-3">
            {{
              show_id == "recentbig"
                ? $t("text_recentbig").toUpperCase()
                : show_id == "T1"
                ? $t("text_newt1").toUpperCase()
                : show_id == "RTP"
                ? $t("text_rtp").toUpperCase()
                : ""
            }}
          </div>
          <!-- <div class="top_arrow">
            <div class="left_right">
              <button class="btn_arrow" @click="turn(show_id, 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn(show_id, 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div> -->
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :autoplay="swiper_casino_options.autoplay"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide
            class="home_game_img"
            v-for="(l, k) in which_swip_type"
            :key="l"
          >
            <div class="home_game_img_div">
              <div class="">
                <img v-lazy="l.iconName" class="w-100" />
                <div
                  class="ps-2 pe-1 pt-3"
                  v-if="show_id.toLowerCase() == 'recentbig'"
                >
                  <p>{{ Math.ceil(Math.random() * 10000 + 10000) + "***" }}</p>
                  <p class="ft-green fw-bolder">
                    {{ Math.ceil(Math.random() * 32000 + 6000) }}
                  </p>
                </div>
                <div
                  class="ps-2 pe-1 pt-3"
                  v-if="show_id.toLowerCase() == 't1'"
                >
                  <p class="newmain_text">{{ l.gameName }}</p>
                  <button
                    class="w-100 btn btn-success"
                    @click="
                      game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                    "
                  >
                    <a>{{ $t("home_text1") }}</a>
                  </button>
                </div>
                <div
                  class="ps-2 pe-1 pt-3"
                  v-if="show_id.toLowerCase() == 'rtp'"
                >
                  <p class="newmain_text">{{ l.gameName }}</p>

                  <div
                    class="d-flex align-items-center flex-column flex-lg-row"
                  >
                    <button
                      class="btn w-lg-60"
                      :class="
                        data_rtp[k] >= 97.5 ? 'btn-success' : 'btn-danger'
                      "
                    >
                      {{ data_rtp[k] }}%
                    </button>
                    <span class="text-end w-lg-40">97.5%</span>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import { reactive } from "vue";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
import newWindow from "./iframe.vue";
export default {
  props: { show_id: String },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    newWindow,
  },
  data() {
    return {
      code: Cookies.get("code"),
      newWindowurl: "",
      data_rtp: [],
    };
  },
  methods: {
    ...mapActions(["getGameList"]),
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    gamelist: function (t) {
      var action_val = "GameLobby";
      if (t.toLowerCase() == "rtp") t = "funta";
      else if (t.toLowerCase() == "recentbig") t = "RANDOM";
      let param = {
        action: action_val,
        body: {
          gametype: t,
          type: "all",
        },
      };
      this.getGameList(param);
    },
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (type.toUpperCase() == "TCG") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
    rtp_random() {
      for (var i = 0; i < 60; i++) {
        this.data_rtp.push((Math.random() * 17 + 90.5).toFixed(2));
      }
    },
  },
  watch: {
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$store.commit("watch_newWindowurl", "close");
      }
    },
    show_id: function (e) {
      this.gamelist(e);
    },
  },
  mounted() {},
  created() {
    this.rtp_random();
    this.gamelist(this.show_id);
  },
  computed: {
    ...mapGetters(["list", "watch_newWindowurl"]),
    which_swip_type: function () {
      if (this.list != null && this.list != "") {
        if (this.show_id.toLowerCase() == "rtp")
          return JSON.parse(localStorage.getItem("funta_all_gamelist"));
        else if (this.show_id.toLowerCase() == "recentbig")
          return JSON.parse(localStorage.getItem("random_all_gamelist"));
        else
          return JSON.parse(
            localStorage.getItem(this.show_id.toLowerCase() + "_all_gamelist")
          );
      } else return [];
    },
  },
};
</script>
