<template>
  <div class="d-flex scroll-new">
    <div class="flex-lg-1 flex-2 me-2" >
      <div
        class="c-pointer h-90"
        style="background: linear-gradient(#451e94, #a060f0, #451e94)"
        @click="click_casino()"
      >
        <img src="@/assets/img/main/casino.png" class="w-100" />
        <div class="p-1 px-2 d-flex align-items-center justify-content-center mt-2">
          <img src="@/assets/img/main/ic_casino_c.png" class="nm_img" />
          <span class="fs_18 fw-bolder">{{ $t("home_text8").toUpperCase() }}</span>
        </div>
        <!-- <p class="p-1 ps-3 text-break">{{ $t("text_main_intro1") }}</p> -->
      </div>
    </div>
    <div class="flex-lg-1 flex-2 me-2" >
      <div
        class="c-pointer h-90"
        style="
          background: linear-gradient(
            rgb(32, 92, 46),
            rgb(14, 119, 61),
            rgb(79, 117, 85)
          );
        "
        @click="click_sport()"
      >
        <img src="@/assets/img/main/sports.png" class="w-100" />
        <div class="p-1 px-2 d-flex align-items-center justify-content-center mt-2">
          <img src="@/assets/img/main/ic_sports_c.png" class="nm_img" />
          <span class="fs_18 fw-bolder">{{ $t("text_sport").toUpperCase() }}</span>
        </div>
        <!-- <p class="p-1 ps-3 text-break">{{ $t("text_main_intro2") }}</p> -->
      </div>
    </div>
    <div class="flex-lg-1 flex-2 me-2" >
      <div
        class="c-pointer h-90"
        style="
          background: linear-gradient(
            rgb(87 57 27),
            rgb(138 80 15),
            rgb(126 85 43)
          );
        "
        @click="set_gametype('funta')"
      >
        <img src="@/assets/img/main/slot.png" class="w-100" />
        <div class="p-1 px-2 d-flex align-items-center justify-content-center mt-2">
          <img src="@/assets/img/main/ic_slot_c.png" class="nm_img" />
          <span class="fs_18 fw-bolder">{{ $t("invest_text14").toUpperCase() }}</span>
        </div>
        <!-- <p class="p-1 ps-3 text-break">{{ $t("text_main_intro3") }}</p> -->
      </div>
    </div>
    <div class="flex-lg-1 flex-2" >
      <div
        class="c-pointer h-90"
        style="
          background: linear-gradient(rgb(82 33 34), rgb(207 36 36), #5f5153);
        "
      >
        <img src="@/assets/img/main/trading.png" class="w-100" />
        <div class="p-1 px-2 d-flex align-items-center justify-content-center mt-2">
          <img src="@/assets/img/main/ic_trading_c.png" class="nm_img" />
          <span class="fs_18 fw-bolder">{{ $t("text_trading").toUpperCase() }}</span>
        </div>
        <!-- <p class="p-1 ps-3 text-break">{{ $t("text_main_intro4") }}</p> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newmaintop",
  methods: {
    click_casino: function () {
      this.$emit("click_casino_o", "casino");
      this.$emit("click_casino", "igaming");
    },
    click_sport: function () {
      this.$router.push("/main_sport");
    },
    set_gametype: function (e) {
      localStorage.setItem("g", e);
      this.$router.push("/slot");
    },
  },
};
</script>
