<template>
  <div class="wc-PageView wc_settlement">
    <div class="wc-PageView_NavigationMenu">
      <div
        class="nm-NavigationMenuModule-footerdisplayed nm-NavigationMenuModule nm-NavigationMenuModule-wide"
      >
        <div class="nm-MenuHeader">
          <div class="nm-MenuHeader_Text">{{ $t("history_title") }}</div>
        </div>
        <div class="nm-MenuItems">
          <div
            class="nm-MenuItem"
            :class="
              this.id == 'report'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="
              this.id = 'report';
              this.name = this.$t('gamerecord');
            "
          >
            <div class="nm-MenuItem_Text">
              {{ $t("gamerecord") }}
            </div>
          </div>
          <!-- <div
            class="nm-MenuItem"
            :class="
              this.id == 'sportreport'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="
              this.id = 'sportreport';
              this.name = this.$t('text_sportreport');
            "
          >
            <div class="nm-MenuItem_Text">
              {{ $t("text_sportreport") }}
            </div>
          </div> -->
          <div
            class="nm-MenuItem"
            :class="
              this.id == 'transaction'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="
              this.id = 'transaction';
              this.name = this.$t('history_transaction');
            "
          >
            <div class="nm-MenuItem_Text">
              {{ $t("history_transaction") }}
            </div>
          </div>
          <div
            class="nm-MenuItem"
            :class="
              this.id == 'mybetkind'
                ? 'nm-MenuItem-selected nm-MenuItem-selectedindicator'
                : ''
            "
            @click="
              this.id = 'mybetkind';
              this.name = this.$t('text_gamereport');
            "
          >
            <div class="nm-MenuItem_Text">
              {{ $t("text_gamereport") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 游戏记录 -->
    <div
      class="wc-PageView_ContentContainer wc-ModulePage_ContentContainer wc_sport"
      style="min-height: 530px"
      :class="!this.show ? '' : 'Hidden'"
    >
      <div>
        <div>
          <div
            class="historyProductSubMenu subMenuFullWidth"
            :class="
              this.id == 'report' || this.id == 'transaction' ? '' : 'Hidden'
            "
          >
            <div class="select_game" v-if="this.id == 'report'">
              {{ $t("gamename") }}：
            </div>
            <div class="select_game" v-if="this.id == 'transaction'">
              {{ $t("transaction_SelectType") }}：
            </div>
            <select v-model="platform" class="rounded-5">
              <option v-for="(i, index) in option" :key="index" :value="i.v">
                {{ i.t }}
              </option>
            </select>
          </div>
          <div v-if="this.id == 'sportreport'" style="padding: 35px 15px 0px">
            <div class="select_game text-black" v-if="this.id == 'sportreport'">
              {{ $t("text_sportreport") }}
            </div>
            <div class="text-black mt-2 p-3 ps-0 pb-0">
              <span
                @click="sport_id = 'ongoing'"
                class="me-3 pb-1 c-pointer"
                :style="
                  sport_id == 'ongoing' ? 'border-bottom:1px solid #000' : ''
                "
                >{{ $t("text_ongoing") }}</span
              >
              <span
                @click="sport_id = 'settle'"
                class="pb-1 c-pointer"
                :style="
                  sport_id == 'settle' ? 'border-bottom:1px solid #000' : ''
                "
                >{{ $t("text_settle") }}</span
              >
            </div>
          </div>
          <div class="history-search-container">
            <div>
              <div class="history-search-menu">
                <div
                  class="toggle-button"
                  :class="this.day == 1 ? 'selected' : ''"
                  @click="this.day = 1"
                >
                  {{ $t("history_date_24") }}
                </div>
                <div
                  class="toggle-button"
                  :class="this.day == 2 ? 'selected' : ''"
                  @click="this.day = 2"
                >
                  {{ $t("history_date_48") }}
                </div>
                <div
                  class="toggle-button"
                  :class="this.day == 0 ? 'selected' : ''"
                  @click="this.day = 0"
                >
                  {{ $t("history_date") }}
                </div>
              </div>

              <div class="tab-content">
                <div class="serach_date" :class="this.day == 0 ? '' : 'Hidden'">
                  <div class="serach_date_card">
                    <div class="dateControlLabel">
                      {{ $t("history_begin") }}:
                    </div>
                    <input
                      type="date"
                      v-model="begin"
                      Style="padding: 15px;"
                      :min="fun_beginmin()"
                    />
                  </div>
                  <div class="serach_date_card">
                    <div class="dateControlLabel">{{ $t("history_end") }}:</div>
                    <input type="date" v-model="end" Style="padding: 15px;" />
                  </div>
                </div>
                <div
                  class="show-history-button rounded-5"
                  @click="this.show = true"
                >
                  {{ $t("history_show") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  游戏记录報表 -->
    <div
      class="wc-PageView_ContentContainer wc-ModulePage_ContentContainer wc_data_block"
      :class="this.show ? '' : 'Hidden'"
    >
      <div>
        <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
          <div class="nh-NavigationHeaderModule_Title">{{ name }}</div>
        </div>
      </div>
      <div class="new-header header-dividing-border">
        <div class="back-button-container">
          <div class="back-button">
            <span class="back-button-text" @click="this.show = false"
              >&lt; {{ $t("history_back") }}
            </span>
          </div>
        </div>

        <div v-if="this.list.length <= 0">
          <div class="dateRange">
            <span class="odds">{{ $t("history_begin") }}&nbsp;</span>
            <span class="rDate">{{ begin }}&nbsp;</span>
            <span class="odds">{{ $t("history_end") }}&nbsp;</span>
            <span class="rDate">{{ end }}</span>
          </div>
          <div class="deeplinkpage">
            <span class="bet365-error" style="padding-left: 15px"
              >{{ $t("nodata") }}
            </span>
          </div>
        </div>
        <div v-else>
          <div class="game_serch" :class="this.id == 'report' ? '' : 'Hidden'">
            <div class="game_card" style="background-color: transparent">
              <div class="text-black mb-2">
                {{ $t("text_totalBetAmount") }}:&nbsp;{{ this.totalBetAmount }}
              </div>
              <div class="text-black">
                {{ $t("text_totalWinLose") }}:<span
                  :style="[
                    totalWinLose > 0
                      ? 'color:#1dbc8b;font-weight:600'
                      : totalWinLose < 0
                      ? 'color:#ff5c43;font-weight:600'
                      : '',
                  ]"
                  >&nbsp;{{ this.totalWinLose }}</span
                >
              </div>
            </div>
            <div class="game_card" v-for="l in list" :key="l">
              <div class="game">
                <div class="game_title_name">{{ l.gametype_name }}</div>
              </div>
              <div class="game_second">
                <img alt="" src="@/assets/img/main/date_icon.png" />
                <div class="game_title_date">
                  {{ l.cdate1 }}
                </div>

                <img alt="" src="@/assets/img/main/time_icon.png" />
                <div class="game_title_date game_title_date2">
                  {{ l.cdate2 }}
                </div>
              </div>
              <div class="game_third">
                <div class="game_title_main">{{ $t("numberid") }}:</div>
                <div class="game_title_name">
                  {{ l.reportid }}
                </div>
              </div>
              <div class="game_fourth">
                <div class="fourth_card">
                  <div class="game_fourth_word">{{ $t("result") }}:&nbsp;</div>
                  <span
                    :style="[
                      l.losewin > 0
                        ? 'color:#1dbc8b;font-weight:600'
                        : l.losewin < 0
                        ? 'color:#ff5c43;font-weight:600'
                        : '',
                    ]"
                    >{{ l.losewin }}</span
                  >
                </div>
                <div class="fourth_card">
                  <div class="game_fourth_word">{{ $t("bet") }}:&nbsp;</div>
                  <div class="game_fourth_word3">
                    {{ l.turnover }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="wc_transaction_block"
            :class="this.id == 'transaction' ? '' : 'Hidden'"
          >
            <div class="new-header header-dividing-border">
              <div class="transaction_data_card">
                <div class="transaction_data_top">
                  <p class="transaction_data_name">{{ $t("transaction") }}</p>
                  <p class="transaction_data_name">{{ $t("staus") }}</p>
                </div>
                <div class="transaction_data_bottom">
                  <div
                    class=""
                    v-if="this.platform == '1' || this.platform == '2'"
                  >
                    <div
                      class="transaction_data_data"
                      v-for="l in list"
                      :key="l"
                    >
                      <div class="transaction_data_number">
                        <h6>{{ Transaction[parseInt(platform) - 1].t }}</h6>
                        <p>
                          {{ $t("transaction_Method") }}:
                          <span>{{ l.method }}</span>
                        </p>
                        <p>
                          {{ $t("transaction_Amount") }}: {{ $t("cur")
                          }}<span>{{ l.payment }}</span>
                        </p>
                        <p>{{ l.cdate }}</p>
                      </div>
                      <div
                        class="transaction_data_number"
                        :style="[
                          l.code == 'Y'
                            ? 'color:#32c396;font-weight:600'
                            : l.code == 'N'
                            ? 'color:#e7323e;font-weight:600'
                            : 'color:#000',
                        ]"
                      >
                        <span v-if="l.code == 'Y'">●</span>
                        <span v-else-if="l.code == 'N'">X</span>
                        <span v-else></span>{{ l.status }}
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="platform == '3'">
                    <div
                      class="transaction_discount"
                      v-for="l in list"
                      :key="l"
                    >
                      <h6>{{ Transaction[parseInt(platform) - 1].t }}</h6>
                      <p>
                        {{ $t("transaction_PromotionName") }}:<span>{{
                          l.name
                        }}</span>
                      </p>
                      <p>
                        {{ $t("transaction_Amount") }}: {{ $t("cur")
                        }}<span>{{ l.payment }}</span>
                      </p>
                      <p>{{ l.cdate }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="game_serch"
            :class="this.id == 'mybetkind' ? '' : 'Hidden'"
          >
            <div class="game_card" v-for="l in gamehis_list" :key="l">
              <div class="game pb-0">
                <div class="game_title_name">
                  {{
                    l.gamekind == "all"
                      ? $t("result")
                      : l.gamekind == "casino"
                      ? $t("home_text6")
                      : l.gamekind == "slot"
                      ? $t("home_text7")
                      : l.gamekind == "sport"
                      ? $t("text_sport")
                      : l.gamekind == "lottery"
                      ? $t("text_lotto")
                      : ""
                  }}
                </div>
              </div>
              <div class="game py-0">
                <div class="game_card">
                  <div class="mb-2" style="color: #636363">
                    {{ $t("text_totalBetAmount") }}&nbsp;:&nbsp;{{
                      l.betamount
                    }}
                  </div>
                  <div style="color: #636363">
                    {{ $t("text_totalWinLose") }}&nbsp;:&nbsp;<span
                      :style="[
                        l.losewin > 0
                          ? 'color:#1dbc8b;'
                          : l.losewin < 0
                          ? 'color:#ff5c43;'
                          : 'color:#636363;',
                      ]"
                      >&nbsp;{{ l.losewin }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="game_serch"
            :class="this.id == 'sportreport' ? '' : 'Hidden'"
          >
            <div v-if="this.filterlist.length <= 0">
              <div class="dateRange">
                <span class="odds">{{ $t("history_begin") }}&nbsp;</span>
                <span class="rDate">{{ begin }}&nbsp;</span>
                <span class="odds">{{ $t("history_end") }}&nbsp;</span>
                <span class="rDate">{{ end }}</span>
              </div>
              <div class="deeplinkpage">
                <span class="bet365-error" style="padding-left: 15px"
                  >{{ $t("nodata") }}
                </span>
              </div>
            </div>
            <div v-else>
              <div
                class="game_card p-0 sport_game_card"
                v-for="l in filterlist"
                :key="l"
              >
                <div
                  class="d-flex justify-content-between bg-orange"
                  style="padding: 1% 2%"
                >
                  <span>{{ l.cdate1 }}</span
                  ><span>{{ l.cdate2 }}</span>
                </div>
                <div
                  class="d-flex justify-content-between text-black mt-2"
                  style="padding: 1% 2%"
                >
                  <div>
                    <p>
                      {{ l.Card }}
                    </p>
                  </div>
                  <div
                    v-if="sport_id == 'ongoing'"
                    class="d-flex align-items-center"
                  >
                    <p class="text-blue fw-bolder">{{ l.odds }}</p>
                  </div>
                  <div v-else>
                    <p class="text-center">{{ $t("staus") }}</p>
                    <p
                      class="text-center fw-bolder"
                      :style="
                        l.status.toLowerCase() == 'win'
                          ? 'color:#1dbc8b'
                          : l.status.toLowerCase() == 'lose'
                          ? 'color:#ff5c43'
                          : ''
                      "
                    >
                      {{ l.status }}
                    </p>
                  </div>
                </div>
                <hr class="my-1 color-orange" />
                <div
                  class="d-flex justify-content-between text-black"
                  style="padding: 1% 2%"
                >
                  <div>
                    <p>{{ $t("text_total_odds") }}</p>
                    <p>{{ $t("text_total_bet") }}</p>
                    <p>
                      {{
                        sport_id == "ongoing"
                          ? $t("text_total_est")
                          : $t("text_total_payout")
                      }}
                    </p>
                  </div>
                  <div>
                    <p class="text-blue fw-bolder text-end">{{ l.odds }}</p>
                    <p class="fw-bolder text-end">{{ l.betamount }}</p>
                    <p
                      class="fw-bolder text-end"
                      :style="
                        l.status.toLowerCase() == 'win'
                          ? 'color:#1dbc8b'
                          : l.status.toLowerCase() == 'lose'
                          ? 'color:#ff5c43'
                          : ''
                      "
                    >
                      {{ l.losewin }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";

export default {
  name: "Home",
  computed: {
    gamehis_list() {
      return this.list.filter((v) => {
        if (v.gamekind != "lottery") {
          return v;
        }
      });
    },
    filterlist() {
      var lang_s = localStorage.getItem("locale") == "cn" ? "zh" : "en";
      var arr = [];

      arr = this.list.filter((v) => {
        if (this.sport_id == "ongoing") {
          return v.status.toLowerCase().includes("open");
        } else {
          return !v.status.toLowerCase().includes("open");
        }
      });

      arr.forEach((v) => {
        v["Card"] = v["Card"].data[2][lang_s];
        if (this.sport_id == "ongoing") {
          v["losewin"] = parseFloat(v["betamount"]) * parseFloat(v["odds"]);
        } else {
          v["losewin"] = parseFloat(v["betamount"]) + parseFloat(v["losewin"]);
        }
      });
      return arr;
    },
  },
  data() {
    return {
      id: "report",
      name: this.$t("gamerecord"),
      begin: dateTime.getToday(),
      end: dateTime.getToday(),
      beginmin: dateTime.getoneday(-7),
      show: false,
      list: [],
      day: 1,
      sumpayment: 0,
      sumturnover: 0,
      week: "",
      sumweek: 0,
      month: "",
      summonth: 0,
      platform: this.$t("allgame"),
      Game: [
        { t: this.$t("allgame"), v: "all" },
        { t: "T1" + " " + this.$t("home_text7"), v: "T1-slot" },
        { t: "FUNTA" + " " + this.$t("home_text7"), v: "FUNTA-slot" },
        { t: "FACHAI" + " " + this.$t("home_text7"), v: "FACHAI-slot" },
        { t: "JILI" + " " + this.$t("home_text7"), v: "JILI-slot" },
        { t: "PS" + " " + this.$t("home_text7"), v: "PS-slot" },
        { t: "KA" + " " + this.$t("home_text7"), v: "KA-slot" },
        { t: "CQ9" + " " + this.$t("home_text7"), v: "CQ9-slot" },
        { t: "RICH" + " " + this.$t("home_text7"), v: "RICH-slot" },
        { t: "PP" + " " + this.$t("home_text7"), v: "PP-slot" },
        { t: "PG" + " " + this.$t("home_text7"), v: "PG-slot" },
        { t: "BG" + " " + this.$t("home_text6"), v: "BG-casino" },
        { t: "YB" + " " + this.$t("home_text6"), v: "YB-casino" },
        { t: "WM" + " " + this.$t("home_text6"), v: "WM-casino" },
        { t: "EVO" + " " + this.$t("home_text6"), v: "EVO-casino" },
        // { t: "XG" + " " + this.$t("home_text6"), v: "XG-casino" },
        { t: "AG" + " " + this.$t("home_text6"), v: "AG-casino" },
        { t: "PP" + " " + this.$t("home_text6"), v: "PP-casino" },
      ],
      Transaction: [
        { t: this.$t("history_transaction_type1"), v: "1" },
        { t: this.$t("history_transaction_type2"), v: "2" },
        // { t: this.t("history_transaction_type3"), v: "3" },
        { t: this.$t("history_transaction_type4"), v: "3" },
        // { t: this.t("history_transaction_type5"), v: "5" },
        // { t: this.t("history_transaction_type6"), v: "6" },
        // { t: this.t("history_transaction_type7"), v: "7" },
        // { t: this.t("history_transaction_type8"), v: "8" },
      ],
      option: [],
      sport_id: "ongoing",
      totalBetAmount: 0,
      totalWinLose: 0,
    };
  },
  watch: {
    day: function (e) {
      if (e == 1) {
        this.begin = dateTime.getToday();
      } else if (e == 2) {
        this.begin = dateTime.getoneday(-2);
      }
    },
    id: function (e) {
      if (e == "report") {
        this.option = this.Game;
        this.platform = "all";
      } else if (e == "transaction") {
        this.option = this.Transaction;
        this.platform = "1";
      }
      this.begin = dateTime.getToday();
      this.show = false;
    },
    show: function (e) {
      if (e) {
        this.showList();
      }
    },
  },
  methods: {
    showList: function () {
      this.list = [];
      let param = null;
      switch (this.id) {
        case "report":
          param = {
            action: "mybet",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
              name: this.platform,
              begindate: this.begin,
              enddate: this.end,
            },
          };
          break;
        case "income":
          param = {
            action: "rebate",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
              begindate: this.begin,
              enddate: this.end,
            },
          };
          break;
        case "cash":
          param = {
            action: "cashback",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
            },
          };
          break;
        case "transaction":
          param = {
            action: "record",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
              methods: this.platform,
              begindate: this.begin,
              enddate: this.end,
            },
          };
          break;
        case "mybetkind":
          param = {
            action: "mybet_kind",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
              begindate: this.begin,
              enddate: this.end,
            },
          };
          break;
        case "sportreport":
          param = {
            action: "mybet",
            body: {
              uid: localStorage.getItem("uid"),
              session_code: Cookies.get("code"),
              name: "sport-sport",
              begindate: this.begin,
              enddate: this.end,
            },
          };
          break;
      }

      this.$store.dispatch("getpost", param).then((res) => {
        switch (this.id) {
          case "imcome":
            this.sumpayment = res.sum_payment;
            this.sumturnover = res.sum_turnover;
            break;
          case "cash":
            this.summonth = res.this_month[0].commision_profit;
            this.month = res.this_month[0].cdate;
            this.sumweek = res.this_week[0].commision_profit;
            this.week = res.this_week[0].cdate;
            break;
          case "report":
            this.totalBetAmount = res.totalBetAmount;
            this.totalWinLose = res.totalWinLose;
            break;
          case "transaction":
            break;
        }
        this.list = res.data;
      });
    },
    fun_beginmin: function () {
      if (this.id == "mybetkind") return "";
      else return this.beginmin;
    },
  },
  created() {
    this.option = this.Game;
    this.platform = "all";
  },
};
</script>
