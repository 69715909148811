<template>
  <div id="invite">
    <div class="mt-xl-3 mt-1">
      <div class="d-flex w-100">
        <div class="w-100">
          <div class="Invite_inviteBlock__UcJ7x">
            <div class="Invite_invite__G9kao box_shadow">
              <div class="Invite_inviteUrl__wXcW7 border_ccc">
                <span class="Invite_title__N+heX">
                  {{ $t("share_invite_title") }}
                </span>
                <div style="margin-top: 40px">
                  <div>
                    <span class="Invite_copyTitle__6WDwK">
                      {{ $t("share_invite_url") }}:
                    </span>
                    <div class="Invite_copyContent__KAT5V">
                      <div class="Invite_content__PJLcj">
                        <span>
                          {{ shareurl }}
                        </span>
                      </div>
                      <div class="Invite_copyIcon__9RbvF">
                        <span
                          role="img"
                          aria-label="copy"
                          tabindex="-1"
                          class="anticon anticon-copy"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="copy"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            @click="Copy('url')"
                            ref="copy"
                          >
                            <path
                              d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 40px">
                  <div>
                    <span class="Invite_copyTitle__6WDwK">
                      {{ $t("share_invite_code") }}:
                    </span>
                    <div class="Invite_copyContent__KAT5V">
                      <div class="Invite_content__PJLcj">
                        <span> {{ sharecode }} </span>
                      </div>
                      <div class="Invite_copyIcon__9RbvF">
                        <span
                          role="img"
                          aria-label="copy"
                          tabindex="-1"
                          class="anticon anticon-copy"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="copy"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            @click="Copy('code')"
                            ref="copy"
                          >
                            <path
                              d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Invite_inviteInfo__kjoEL">
                <div class="Invite_inviteInfoTop__BOMlA border_ccc">
                  <div class="Invite_headerText__HO5-e">
                    <div class="Invite_icon__oK7IR">
                      <span role="img" class="anticon">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.46667 14.9333C3.36 14.9333 0 11.5733 0 7.46667C0 3.36 3.36 0 7.46667 0C9.62667 0 11.68 0.933333 13.0933 2.56C13.2267 2.72 13.2267 2.98667 13.0667 3.12C12.9067 3.25333 12.64 3.25333 12.5067 3.09333C11.2267 1.62667 9.38667 0.8 7.46667 0.8C3.78667 0.8 0.8 3.78667 0.8 7.46667C0.8 11.1467 3.78667 14.1333 7.46667 14.1333C10.0533 14.1333 12.4267 12.6133 13.52 10.2667C13.6 10.0533 13.84 9.97333 14.0533 10.08C14.2667 10.16 14.3467 10.4 14.24 10.6133C13.0133 13.2267 10.3733 14.9333 7.46667 14.9333Z"
                            fill="#FBFBFB"
                          ></path>
                          <path
                            d="M13.0933 3.46495H10.6667C10.4533 3.46495 10.2667 3.27828 10.2667 3.06495C10.2667 2.85161 10.4533 2.66495 10.6667 2.66495H12.6933V0.638281C12.6933 0.424948 12.88 0.238281 13.0933 0.238281C13.3067 0.238281 13.4933 0.424948 13.4933 0.638281V3.06495C13.4933 3.30495 13.3067 3.46495 13.0933 3.46495Z"
                            fill="#FBFBFB"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.80794 10.1218C4.10281 9.41671 3.70667 8.46034 3.70667 7.46313C3.70667 6.46591 4.10281 5.50954 4.80794 4.8044C5.51308 4.09927 6.46945 3.70312 7.46667 3.70312C8.46388 3.70312 9.42025 4.09927 10.1254 4.8044C10.8305 5.50954 11.2267 6.46591 11.2267 7.46313C11.2267 8.46034 10.8305 9.41671 10.1254 10.1218C9.42025 10.827 8.46388 11.2231 7.46667 11.2231C6.46945 11.2231 5.51308 10.827 4.80794 10.1218ZM6.90667 8.76984H9.12C9.33333 8.76984 9.52 8.60984 9.52 8.39651C9.52 8.18318 9.33333 7.99651 9.12 7.99651H7.30667V5.48984C7.30667 5.27651 7.12 5.08984 6.90667 5.08984C6.69333 5.08984 6.50667 5.27651 6.50667 5.48984V8.36984C6.50667 8.58318 6.69333 8.76984 6.90667 8.76984Z"
                            fill="white"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <span> {{ $t("share_invite_update") }} </span>
                  </div>
                  <div class="Invite_inviteInfoTopData__eXFoq">
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ total_invited }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_total") }}
                        </span>
                      </div>
                    </div>
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ $t("cur") }} {{ total_income }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_total_income") }}
                        </span>
                      </div>
                    </div>
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ invited_today }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_today") }}
                        </span>
                      </div>
                    </div>
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ $t("cur") }} {{ income_today }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_income_today") }}
                        </span>
                      </div>
                    </div>
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ $t("cur") }} {{ salary_estimate }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_salary") }}</span
                        >
                      </div>
                    </div>
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ $t("cur") }} {{ lastmonth_salary }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_last_month") }}
                        </span>
                      </div>
                    </div>
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ $t("cur") }} {{ invited_bonus }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_text1") }}
                        </span>
                      </div>
                    </div>
                    <div class="Invite_inviteCountItem__snwbE">
                      <div>
                        <span class="Invite_inviteCountItemNum__OuUfK">
                          {{ $t("cur") }} {{ commission }}
                        </span>
                      </div>
                      <div>
                        <span style="font-weight: bold">
                          {{ $t("share_invite_text2") }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="Invite_inviteInfoBottom__j-eV2 Invite_enableSalary__7UMKF"
                >
                  <div class="Invite_inviteInfoBottomLeft__dYGJQ">
                    <span class="text-white">
                      {{ $t("share_invite_text3") }}
                    </span>
                  </div>
                  <div class="Invite_inviteInfoBottomMiddle__DU9OM">
                    <img
                      src="@/assets/img/invite/E5854C9E8876ADCAE251E4F5F0686426.png"
                    />
                  </div>
                  <div class="Invite_inviteInfoBottomRight__8w+Iu">
                    <div class="Invite_amount__trz-q">
                      <span class="text_gold"> {{ $t("cur") }} 500000 </span>
                    </div>
                    <div class="Invite_text__kV-Av">
                      <span class="text-white">
                        {{ $t("share_invite_text4") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Invite_referralCardOverview__Kxwy-">
              <div class="Invite_referralCardOverviewTitle__ZgbIc">
                <span class="text_gray"> {{ $t("share_invite_text5") }}</span>
              </div>
              <div class="Invite_referralCardOverviewInner__a0qRy">
                <div class="Invite_referralCardOverviewInnerItem__6mZcE">
                  <div class="Invite_referralCardOverviewInnerItemLeft__bt2a8">
                    <img
                      src="@/assets/img/invite/0CC39C3DE7A303822618DA6CDB4F6126.png"
                    />
                  </div>
                  <div class="Invite_referralCardOverviewInnerItemRight__gZO3Q">
                    <div>
                      <span class="text_gray">
                        {{ $t("share_invite_text6") }}
                      </span>
                    </div>
                    <div>
                      <span class="Invite_amount__trz-q text_green">
                        {{ $t("cur") }}
                        <span> 589,582,800 </span>
                      </span>
                    </div>
                    <div>
                      <span class="text_gray">
                        <span> 2,947,914 </span>
                        {{ $t("share_invite_text7") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="Invite_referralCardOverviewInnerItem__6mZcE">
                  <div class="Invite_referralCardOverviewInnerItemLeft__bt2a8">
                    <img
                      src="@/assets/img/invite/11E00D24FCA8FA1B437B102F593F9747.png"
                    />
                  </div>
                  <div class="Invite_referralCardOverviewInnerItemRight__gZO3Q">
                    <div>
                      <span class="text_gray">
                        {{ $t("share_invite_text8") }}
                      </span>
                    </div>
                    <div>
                      <span class="Invite_amount__trz-q text_green">
                        {{ $t("cur") }}
                        <span> 572,242,000 </span>
                      </span>
                    </div>
                    <div>
                      <span class="text_gray">
                        <span> 2,861,210 </span>
                        {{ $t("share_invite_text7") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="Invite_referralCardOverviewInnerItem__6mZcE">
                  <div class="Invite_referralCardOverviewInnerItemLeft__bt2a8">
                    <img
                      src="@/assets/img/invite/985F4028EDCDFA19BCC6718DA6706EDE.png"
                    />
                  </div>
                  <div class="Invite_referralCardOverviewInnerItemRight__gZO3Q">
                    <div>
                      <span class="text_gray">
                        {{ $t("share_invite_text9") }}
                      </span>
                    </div>
                    <div>
                      <span class="Invite_amount__trz-q text_green">
                        {{ $t("cur") }}
                        <span> 554,901,400 </span>
                      </span>
                    </div>
                    <div>
                      <span class="text_gray">
                        <span> 2,774,507 </span>
                        {{ $t("share_invite_text7") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="BonusWork_bonusWorkInviteBox__iZN70">
              <div class="BonusWork_bonusWorkInviteBoxTitle__8EOZu">
                <span class="text_gray"> {{ $t("share_invite_text10") }} </span>
              </div>
              <div class="BonusWork_bonusWorkInviteBoxInner__ASBgm">
                <div class="BonusWork_bonusWorkInviteBoxInnerLeft__opcZZ">
                  <div
                    class="BonusWork_bonusWorkInviteBoxInnerLeftTitle__ty6r9"
                  >
                    <span> {{ $t("share_invite_text11") }}</span>
                  </div>
                  <div
                    class="BonusWork_bonusWorkInviteBoxInnerLeftContent__vpEEw"
                  >
                    <span>{{ $t("share_invite_text12") }} </span>
                  </div>
                </div>
                <div class="BonusWork_bonusWorkInviteBoxInnerRight__m2cpT">
                  <img
                    src="@/assets/img/invite/FFBC862A0DA32ABEB7BF87E14FCE06E2.png"
                  />
                </div>
              </div>
            </div>
            <dailirank />
            <div class="ChannelAward_root__3On+a">
              <div class="ChannelAward_title__-okQw text_gold">
                {{ $t("share_invite_text6") }}
              </div>
              <div>
                <div class="GbTable_root__aRhLC">
                  <table
                    class="GbTable_table__moqvH"
                    style="width: 500px; min-width: 100%; table-layout: auto"
                  >
                    <thead class="ant-table-thead">
                      <tr>
                        <th>{{ $t("share_invite_text13") }}</th>
                        <th>{{ $t("share_invite_text14") }}</th>
                        <th>{{ $t("share_invite_text15") }}</th>
                        <th>{{ $t("share_invite_text16") }}</th>
                      </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw"> 5 </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 500
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 150
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 100
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            20
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 2000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 600
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 400
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            50
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 5000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 1500
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 1000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            100
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 10000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 3000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 2000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            200
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 20000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 6000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 4000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            500
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 50000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 15000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 10000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            1000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 100000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 30000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 20000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            2000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 200000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 60000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 40000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            5000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 500000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 150000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 100000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            10000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 1000000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 300000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 200000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            20000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 2000000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 600000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 400000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            50000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 5000000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 1500000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 1000000
                          </span>
                        </td>
                      </tr>
                      <tr class="ant-table-row ant-table-row-level-0">
                        <td>
                          <span class="ChannelAward_userCount__hA5bw">
                            100000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 10000000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 3000000
                          </span>
                        </td>
                        <td>
                          <span class="ChannelAward_amount__8ICBA">
                            {{ $t("cur") }} 2000000
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="ReferralApostas_referralApostasBox__04p1r">
              <div class="ReferralApostas_referralApostasBoxTitle__scV8W">
                <span class="text_gray"> {{ $t("share_invite_text9") }} </span>
              </div>
              <div class="ReferralApostas_referralApostasBoxInner__Wimw7">
                <div class="ReferralApostas_referralApostasBoxInnerItem__8Ydj0">
                  <div
                    class="ReferralApostas_referralApostasBoxInnerItemTop__esrsz"
                  >
                    <p>{{ $t("share_invite_text17") }}</p>
                    <p class="ReferralApostas_p5__jbZhD">
                      {{ $t("share_invite_text18") }}
                    </p>
                    <p>- {{ $t("share_invite_text19") }}</p>
                    <p>- {{ $t("share_invite_text20") }}</p>
                    <p>- {{ $t("share_invite_text21") }}</p>
                  </div>
                  <div
                    class="ReferralApostas_referralApostasBoxInnerItemBottom__fKzsF"
                  >
                    <span>
                      {{ $t("share_invite_text22") }}
                    </span>
                  </div>
                </div>
                <div class="ReferralApostas_referralApostasBoxInnerItem__8Ydj0">
                  <img
                    src="@/assets/img/invite/832353834615D80F9B7221FB2FBCB9F0.png"
                  />
                </div>
                <div
                  class="ReferralApostas_referralApostasBoxInnerItem__8Ydj0 ReferralApostas_referralApostasBoxInnerBack__EVFqs"
                >
                  <img
                    src="@/assets/img/invite/F253A8AA9B6D6B604ED66261D5072D8B.png"
                  />
                </div>
              </div>
            </div>
            <div class="TopInvited_root__k3D0s">
              <div class="TopInvited_cardItem__WQDDv">
                <div class="TopInvited_title__LaDu-">
                  {{ $t("share_invite_text23") }}
                </div>
                <div class="TopInvited_cardItem__WQDDv_2 box_shadow">
                  <div class="TopInvited_content__pjrvK">
                    <div class="TopInvited_rankingPanel__eWlro">
                      <div class="TopInvited_rankingItem__EcbOT">
                        <div class="TopInvited_userInfo__wbYkW">
                          <div class="TopInvited_index__OAw90">
                            {{ $t("share_invite_text24") }}
                          </div>
                          <div class="TopInvited_avatar__jD2+q">
                            <img
                              src="@/assets/img/big_head/73610A06CBCDBDC443EC170CBECADA17.jpg"
                              alt=""
                            />
                          </div>
                          <div class="TopInvited_name__zHCWX">User27**49</div>
                        </div>
                        <div class="TopInvited_income__JJmVO">
                          {{ $t("cur") }} 980,560,970
                        </div>
                      </div>
                      <div class="TopInvited_rankingItem__EcbOT">
                        <div class="TopInvited_userInfo__wbYkW">
                          <div class="TopInvited_index__OAw90">
                            {{ $t("share_invite_text25") }}
                          </div>
                          <div class="TopInvited_avatar__jD2+q">
                            <img
                              src="@/assets/img/big_head/3FAC106C0CA260773D90458A289E3C7C.jpg"
                              alt=""
                            />
                          </div>
                          <div class="TopInvited_name__zHCWX">User50**61</div>
                        </div>
                        <div class="TopInvited_income__JJmVO">
                          {{ $t("cur") }} 880,350,970
                        </div>
                      </div>
                      <div class="TopInvited_rankingItem__EcbOT">
                        <div class="TopInvited_userInfo__wbYkW">
                          <div class="TopInvited_index__OAw90">
                            {{ $t("share_invite_text26") }}
                          </div>
                          <div class="TopInvited_avatar__jD2+q">
                            <img
                              src="@/assets/img/big_head/DCA6E90D81B3D0DB9CDCA502FD1F3F7E.jpg"
                              alt=""
                            />
                          </div>
                          <div class="TopInvited_name__zHCWX">User04**09</div>
                        </div>
                        <div class="TopInvited_income__JJmVO">
                          {{ $t("cur") }} 780,329,970
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="TopInvited_cardItem__WQDDv">
                <div class="TopInvited_title__LaDu-">
                  {{ $t("share_invite_text27") }}
                </div>
                <div class="TopInvited_cardItem__WQDDv_2_2 box_shadow">
                  <div class="TopInvited_content__pjrvK">
                    <div
                      class="TopInvited_rankingPanel__eWlro TopInvited_rankingPanel__eWlro2"
                    >
                      <div>
                        <div class="GbLiveRecords_root__rUMFM">
                          <table class="GbLiveRecords_table__8sJwz">
                            <tbody
                              class="GbLiveRecords_tableBody__ZIO89 GbLiveRecords_largeSize__pwk1o"
                            >
                              <tr class="GbLiveRecords_tableRow__cUBz5">
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: left"
                                >
                                  <span class="GbLiveRecords_player__jbulp">
                                    <div>
                                      <img
                                        class="GbLiveRecords_playerAvatar__nIO0E"
                                        src="@/assets/img/big_head/052CF049EB71228A9A7EA5522BA73277.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <span> User0265523844 </span>
                                  </span>
                                </td>
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: right"
                                >
                                  <span class="GbLiveRecords_amount__iAjvj">
                                    {{ $t("cur") }} 2
                                  </span>
                                </td>
                              </tr>
                              <tr class="GbLiveRecords_tableRow__cUBz5">
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: left"
                                >
                                  <span class="GbLiveRecords_player__jbulp">
                                    <div>
                                      <img
                                        class="GbLiveRecords_playerAvatar__nIO0E"
                                        src="@/assets/img/big_head/052CF049EB71228A9A7EA5522BA73277.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <span> User2172964398 </span>
                                  </span>
                                </td>
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: right"
                                >
                                  <span class="GbLiveRecords_amount__iAjvj">
                                    {{ $t("cur") }} 10
                                  </span>
                                </td>
                              </tr>
                              <tr class="GbLiveRecords_tableRow__cUBz5">
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: left"
                                >
                                  <span class="GbLiveRecords_player__jbulp">
                                    <div>
                                      <img
                                        class="GbLiveRecords_playerAvatar__nIO0E"
                                        src="@/assets/img/big_head/3FAC106C0CA260773D90458A289E3C7C.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <span> User4164906812 </span>
                                  </span>
                                </td>
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: right"
                                >
                                  <span class="GbLiveRecords_amount__iAjvj">
                                    {{ $t("cur") }} 2
                                  </span>
                                </td>
                              </tr>
                              <tr class="GbLiveRecords_tableRow__cUBz5">
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: left"
                                >
                                  <span class="GbLiveRecords_player__jbulp">
                                    <div>
                                      <img
                                        class="GbLiveRecords_playerAvatar__nIO0E"
                                        src="@/assets/img/big_head/B1AB22E6AA23D3ACD47BAD6C3EFD2BB9.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <span> User0351626747 </span>
                                  </span>
                                </td>
                                <td
                                  class="GbLiveRecords_tableRowItem__Cuqxb TopInvited_tdClass__+i-RI"
                                  style="text-align: right"
                                >
                                  <span class="GbLiveRecords_amount__iAjvj">
                                    {{ $t("cur") }} 10
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ReferralFooter_referralFooterBox__Z5dI1">
              <div class="ReferralFooter_referralFooterBoxInner__mDVxT">
                <div class="ReferralFooter_referralFooterBoxInnerLeft__AZfyl">
                  <img
                    src="@/assets/img/invite/D285DAC79ABA8003CFD1013D7A04533C.png"
                  />
                </div>
                <div class="ReferralFooter_referralFooterBoxInnerRight__DxDiT">
                  <p>
                    {{ $t("share_invite_text28") }}
                  </p>
                  <p>{{ $t("share_invite_text29") }}</p>
                  <p style="color: rgb(252, 233, 11); cursor: pointer">
                    https://www.richking.cc
                  </p>
                  <p>{{ $t("share_invite_text30") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import dailirank from "../daili_dw_rank.vue";

export default {
  name: "member",
  data() {
    return {
      code: Cookies.get("code"),
      shareurl: "",
      sharecode: "",
      total_invited: 0,
      total_income: 0,
      invited_today: 0,
      income_today: 0,
      salary_estimate: 0,
      lastmonth_salary: 0,
      invited_bonus: 0,
      commission: 0,
    };
  },
  components: {
    dailirank,
  },
  computed: {},
  watch: {},
  created() {
    if (this.code != "") {
      this.share();
      this.update_hourly();
    }
  },
  mounted() {},
  methods: {
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.shareurl = res["url"];
          this.sharecode = res["ref"];
        }
      });
    },
    update_hourly: function () {
      let param = {
        action: "invite",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          var data = res["data"][0];
          this.total_invited = data.total_invited;
          this.total_income = data.total_income;
          this.invited_today = data.invited_today;
          this.income_today = data.income_today;
          this.salary_estimate = data.salary_estimate;
          this.lastmonth_salary = data.lastmonth_salary;
          this.invited_bonus = data.invited_bonus;
          this.commission = data.commission;
        }
      });
    },
    Copy: function (e) {
      var content = e == "code" ? this.sharecode : this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
  },
};
</script>
